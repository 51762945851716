import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

/**
 * Table component renders a table with customizable features such as sorting, searching, and pagination.
 * @param {Object} props - Props for the Table component.
 * @param {Array} props.data - Data to be displayed in the table.
 * @param {boolean} props.sort - Flag indicating if sorting is enabled.
 * @param {boolean} props.search - Flag indicating if searching is enabled.
 * @param {boolean} props.pagination - Flag indicating if pagination is enabled.
 * @returns {JSX.Element} - Rendered component.
 */
const Table = ({ data, sort, search, pagination }) => {
  /* Custom filter function for the status column */
  const statusFilter = (filterVal, data) => {
    if (!filterVal) {
      return data;
    }

    return data.filter((row) =>
      row.status.component?.props?.label?.toLowerCase().includes(filterVal.toLowerCase()),
    );
  };

  /* Table columns configuration */
  const columns = [
    {
      dataField: "testType",
      text: "Test type",
      sort: sort,
      filter: search && textFilter({ placeholder: "Test type" }),
      headerClasses: "column-heading-name",
    },
    {
      dataField: "sampleType",
      text: "Sample type",
      sort: sort,
      filter: search && textFilter({ placeholder: "Sample type" }),
      headerClasses: "column-heading-name",
    },
    {
      dataField: "reportedDate",
      text: "Reported date",
      sort: sort,
      filter: search && textFilter({ placeholder: "Reported date" }),
    },
    {
      dataField: "doctor",
      text: "Doctor",
      sort: sort,
      filter: search && textFilter({ placeholder: "Doctor" }),
    },
    {
      dataField: "status",
      text: "Status",
      sort: sort,
      filter:
        search &&
        textFilter({
          placeholder: "Status",
          onFilter: statusFilter,
        }),
      formatter: (cell, row) => row.status.component,
    },
  ];

  /* Table pagination options */
  const options = {
    sizePerPage: 4, // Number of records per page
    pageStartIndex: 1, // First page index
    paginationSize: 1, // Number of pagination navigation items (pages)
    paginationTotalRenderer: () => null, // Render nothing for total
    prePageText: "<", // Customize the text for the previous page button
    nextPageText: ">", // Customize the text for the next page button
    totalSize: data.length, // Total number of records
    showTotal: true, // Show the total number of records
    alwaysShowAllBtns: true, // Always show all pagination buttons (first, last, prev, next)
    withFirstAndLast: false, // Do not show first and last buttons
    hideSizePerPage: true, // Hide the dropdown for selecting the number of records per page
    // Add a function to conditionally apply classes to rows
    rowClasses: (data) => {
      // Assuming each item in your data array has a "status" field with "raw" property

      return data.status.raw === "registered" ? "registered" : "";
    },
  };

  /* Render the Bootstrap table component with provided data, columns, filters, and pagination */
  return (
    <BootstrapTable
      columns={columns} // Configuration of table columns
      data={data} // Data to be displayed in the table
      filter={search && filterFactory()} // Enable filtering if the search flag is true
      keyField="id" // Unique key field for each row
      pagination={pagination && paginationFactory(options)} // Enable pagination if the pagination flag is true
    />
  );
};

export default Table;
