import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SearchForm from "../../stories/Forms/SearchForm";
import {
  BarcodeOneTubeFields,
  BarcodeTwoTubeFields,
  links,
} from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import Toaster from "../../stories/Toasts/Toaster";
import { postUrlBarcode } from "../../utils/constants";
import { enterBarcodeSchema } from "../../stories/Forms/validationSchema";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import { formatTestType, TimeFormatter } from "../../utils/utils";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

function EnterBarcode() {
  // Get ID from URL and initialize navigation
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables for managing form data and UI state
  const [formData, setFormData] = useState({});
  const [secondaryBarcode, setSecondaryBarcode] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [userData, setUserData] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState();
  const [isRejectDisabled, setIsRejectDisabled] = useState(true);
  const [updateKey, setUpdateKey] = useState(0);
  const [data, setData] = useState([]);
  // API endpoints for GET and POST requests
  const getUrl = `/api/phlebotomists-portal/test-confirmation/${id}/`;
  const postUrl = `/api/phlebotomists-portal/selected-test-type/${id}/`;
  const rejectUrl = `/api/phlebotomists-portal/kit-rejection`;

  // Custom hook to handle API calls and responses
  const {
    callApi,
    resultGet,
    resultPost,
    isLoading,
    serverError,
    showSuccess,
    showError,
    tokenError,
    toggleShowSuccess,
    toggleShowError,
  } = useApis();

  // Access authentication data from context
  const auth = useAuth();

  // Submit form data via POST request
  const handleSubmit = async (formData) => {
    setData((prevData) => {
      return { ...prevData, ...formData }; // Adjust based on your update logic
    });

    // Wait for the meet_details to be updated before proceeding
    if (formData.sample_collection_date && formData.sample_collection_time) {
      const formattedDateTime = await TimeFormatter(
        formData.sample_collection_date,
        formData.sample_collection_time,
      );

      // Update formData with the formatted datetime
      formData.sample_collection_time = formattedDateTime;
    }

    if (formData.number_of_attempt !== undefined) {
      formData.number_of_attempt = parseInt(formData.number_of_attempt, 10);
    }
    // Call the API with updated formData    
    await callApi(formData, postUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Fetch initial data on mount
  useEffect(() => {
    auth.setTitle("PATIENT RECORD");
    const fetchData = async () => {
      await callApi("", getUrl, "GET", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });
    };

    fetchData();
  }, [id]);

  // Update state based on API responses
  useEffect(() => {
    if (resultGet) {
      setUserData(resultGet.booking_info.test_type);
      localStorage.setItem('booking_id', resultGet?.booking_info?.booking_id)
    }
    if (resultPost) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        primary_barcode: resultPost.primary_barcode,
        secondary_barcode: resultPost.secondary_barcode,
        expiry_date: resultPost.expiry_date,
      }));
      setSecondaryBarcode(resultPost.secondary_barcode);
      setExpiryDate(resultPost.expiry_date);
      setIsRejectDisabled(!resultPost.primary_barcode);
    } else {
      toggleShowError(); // Show error toaster on barcode validation failure
    }
  }, [resultGet, resultPost]);

  // Handle server errors
  useEffect(() => {
    if (serverError) {
      if (
        serverError.includes("Please enter valid primary barcode") ||
        serverError.includes("Primary barcode does not exist") ||
        serverError.includes("Entered primary barcode is already used") ||
        serverError.includes("Primary barcode has expired")
      ) {
        // Reset form fields if barcode errors occur
        setFormData({
          primary_barcode: "",
          secondary_barcode: "",
          expiry_date: "",
        });
        setSecondaryBarcode("");
        setExpiryDate("");
        setIsRejectDisabled(true);
        setUpdateKey((prev) => prev + 1); // Trigger re-render
      }
    }
  }, [serverError]);

  // Handle successful submission
  useEffect(() => {
    const redirect = `/appointment-confirmation/${id}`;
    if (resultPost?.selected_test_type === true) {
      auth?.showToast(
        "Success",
        `${resultPost.test_type} Test assigned to kit ${resultPost.primary_barcode}`,
        "../assets/mhc.png",
        <ArrowRightCircleFill />
      )
      navigate(redirect);
    } else {
      toggleShowError(); // Show error toaster on API call failure
    }
  }, [resultPost]);

  // Handle 'Reject' button click
  const handleRejectClick = () => {
    const payload = {
      ...formData,
      testkit: resultPost?.testkit || null, // Include the testkit from resultPost
    };

    callApi(payload, rejectUrl, "PUT", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });

    navigate(`/appointment-confirmation/${id}`, {
      state: {
        rejectPath: true,
        primary_barcode: resultPost?.primary_barcode || null,
        secondary_barcode: resultPost?.secondary_barcode || null,
        testkit: resultPost?.testkit || null, // Optionally pass it to the next page
      },
    });
  };

  const handlePrimaryBarcodeChange = async (event) => {
    const primaryBarcode = event.target.value.trim();
    setIsRejectDisabled(!primaryBarcode);

    if (primaryBarcode) {
      await callApi(
        {
          primary_barcode: primaryBarcode,
          test_type: formatTestType(userData),
        }, // Request payload
        postUrlBarcode, // API URL
        "POST", // HTTP method
        {
          Authorization: `Bearer ${auth.accessToken}`, // Authorization header
          "Content-Type": "application/json", // Content-Type header
        },
      );
    }
  };

  // Handle 'Back' button click
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        {isLoading && <p>Loading...</p>}
        <SearchForm
          className={"enter-barcode-wrapper"}
          colStructure={7}
          data={{ ...formData, test_type: userData, ...data }}
          expiryDate={expiryDate}
          fields={
            resultGet?.booking_info?.vessel_info_2
              ? BarcodeTwoTubeFields
              : BarcodeOneTubeFields
          }
          formError={"Please fill in the required fields"}
          helptext={expiryDate}
          helptextClass={"invalid-feedback-small"}
          isRejectDisabled={isRejectDisabled}
          key={updateKey}
          onBackClick={handleBackClick}
          onBlur={handlePrimaryBarcodeChange}
          onRejectClick={handleRejectClick}
          onSubmit={handleSubmit}
          secondaryBarcode={secondaryBarcode}
          textAfter={
            resultGet?.booking_info?.vessel_info_2 ? (
              "2. After completing blood collection, enter the date and time the test was performed"
            ) : (
              <div>
                <span className="underline-text">
                  2. <span className="danger-text">After</span> completing blood
                  collection,
                </span>{" "}
                enter the date and time the test was performed
              </div>
            )
          }
          useLocalStorage={true}
          validationSchema={enterBarcodeSchema}
        />
        {(serverError || tokenError) && (
          <Toaster
            body={tokenError ? tokenError : serverError}
            header="Error"
            onClose={toggleShowError}
            position="top-end"
            show={tokenError ? tokenError : showError}
          />
        )}
      </LoggedInLayout>
    </>
  );
}

export default EnterBarcode;
