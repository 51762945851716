import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import ConfirmationModal from "../../stories/Modals/ConfirmationModal";
import { sessionWarningMessage } from "../../utils/constants";

// Create AuthContext for managing authentication and authorization
const AuthContext = createContext();

// Provider component to manage authentication and authorization state
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true",
  );
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token") || "",
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token") || "",
  );
  const [user, setUser] = useState(localStorage.getItem("user_id"));
  const [showWarning, setShowWarning] = useState(false);
  const [userInitials, setUserInitials] = useState("guest");
  const [toast, setToast] = useState(null);

  const showToast = (header, body, image, icon) => {
    const Icon = icon;
    setToast({
      header: header,
      body: body,
      imgSrc: image,
      icon: Icon
    });
  };

  // Inactivity and warning time constants
  const INACTIVITY_TIME = 2 * 60 * 60 * 1000;
  const WARNING_TIME = 5 * 60 * 1000;

  let warningTimeout;
  let logoutTimeout;

  // Reset inactivity timers
  const resetTimers = useCallback(() => {
    clearTimeout(warningTimeout);
    clearTimeout(logoutTimeout);

    if (isAuthenticated) {
      warningTimeout = setTimeout(() => {
        setShowWarning(true);
      }, INACTIVITY_TIME);

      logoutTimeout = setTimeout(() => {
        setShowWarning(false);
        logout();
      }, INACTIVITY_TIME + WARNING_TIME);
    }
  }, [isAuthenticated]);

  // Handle user activity to reset timers
  const handleUserActivity = useCallback(() => {
    resetTimers();
    setShowWarning(false);
  }, [resetTimers]);

  // Effect to add/remove event listeners for user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    if (isAuthenticated) {
      resetTimers();
    }

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [isAuthenticated, handleUserActivity, resetTimers]);

  // For dynamic rendering of User initials in profile icon
  useEffect(() => {
    setUserInitials(localStorage.getItem("username"));
  }, [isAuthenticated]);

  // Login function to set authentication state
  const login = (data) => {
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("email", data.email);
    localStorage.setItem("username", data.fullname);
    localStorage.setItem("mfa_enabled", data.mfa_enabled || false);
    data.id && localStorage.setItem("user_id", data.id);
    setRole(data.role);
    setAccessToken(
      data.access_token
        ? data.access_token
        : localStorage.getItem("access_token"),
    );
    setRefreshToken(
      data.refresh_token
        ? data.refresh_token
        : localStorage.getItem("refresh_token"),
    );
    data.id && setUser(data.id);
    setTimeout(() => {
      setIsAuthenticated(localStorage.getItem("isLoggedIn") === "true");
      resetTimers();
    }, 500);
  };

  // Logout function to clear authentication state
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    clearTimeout(warningTimeout);
    clearTimeout(logoutTimeout);
  };

  // Effect to handle changes in local storage
  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem("isLoggedIn") === "true");
      setAccessToken(localStorage.getItem("access_token") || "");
      setRefreshToken(localStorage.getItem("refresh_token") || "");
      setUser(localStorage.getItem("user_id"));
      setRole(localStorage.getItem("role"));
      setEmail(localStorage.getItem("email"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        role,
        user,
        updateState: setUser,
        setTitle,
        title,
        email,
        setEmail,
        accessToken,
        refreshToken,
        setAccessToken,
        setRefreshToken,
        showWarning,
        userInitials,
        toast,
        setToast,
        showToast
      }}
    >
      {children}
      <ConfirmationModal
        handleClose={() => setShowWarning(false)}
        heading="Warning!"
        hideButtons={true}
        message={sessionWarningMessage}
        show={showWarning}
      />
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
