import React, { useEffect } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { changePasswordSchema } from "../stories/Forms/validationSchema";
import { Container, Col, Row } from "react-bootstrap";
import { changePasswordFields, links } from "../stories/Forms/fields";
import useApis from "../services/useAPI";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import { logoutUrl, changePasswordUrl } from "../utils/constants";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";

// Component for handling change password functionality
function ChangePassword() {
  const navigate = useNavigate();
  const auth = useAuth();

  // API call hook with various states
  const {
    callApi,
    serverError,
    isLoading,
    showSuccess,
    tokenError,
    message,
  } = useApis();

  // Function to handle form submission
  const handleSubmit = async (data) => {
    // Call API to change password
    await callApi(data, changePasswordUrl, "PUT", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Effect to handle post password change actions
  useEffect(() => {
    if (message.postMessage && showSuccess) {
      auth?.showToast("Success", "Password changed successfully!")
      auth.logout();
      callApi(null, logoutUrl, "POST");
      navigate("/login");
    }
  }, [message.postMessage, showSuccess, navigate, auth, callApi]);

  useEffect(() => {
  
    if (serverError || tokenError) {
      auth?.showToast("Error", tokenError ? tokenError : serverError)
    }
  },[serverError, tokenError, message])

  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <Container>
        <Row>
          <Col md={6}>
            {/* Form component for changing password */}
            <DynamicForm
              fields={changePasswordFields}
              isLoading={isLoading}
              onSubmit={handleSubmit}
              validationSchema={changePasswordSchema}
            />        
          </Col>
        </Row>
      </Container>
    </LoggedInLayout>
  );
}

export default ChangePassword;
