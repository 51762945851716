import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleTable from "../../stories/Tables/SimpleTable";
import SearchForm from "../../stories/Forms/SearchForm";
import {
  testKitRegistrationFields,
  testKitRejectionFields,
  links,
  testApptRejectionFields,
} from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { Button } from "../../stories/Buttons/Button";
import FormInput from "../../stories/Forms/FormInput";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import SuccessModal from "../../stories/Modals/Modal";
import ConfirmationModal from "../../stories/Modals/ConfirmationModal";
import { format_dt_age, TimeFormatter, extractTime, trimSpaces } from "../../utils/utils";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import { rejectValidationSchema } from "../../stories/Forms/validationSchema";
import Toaster from "../../stories/Toasts/Toaster";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

let secondary_barcode;
function AppointmentConfirmation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const postUrl = `/api/phlebotomists-portal/test-association/${id}/`;
  const putUrlReject = `/api/phlebotomists-portal/kit-rejection`;
  const [submitSuccess, setSubmitSuccess] = useState();
  const [notes, setNotes] = useState("");
  const [barcodeValue, setBarcodeValue] = useState("");
  const location = useLocation();
  const { rejectPath } = location.state || {};

  // Custom hook for API handling
  const {
    callApi,
    resultGet,
    resultPost,
    isLoading,
    serverError,
    message,
    modalShow,
    tokenError,
    setModalShow,
    toggleShowError,
  } = useApis();
  const auth = useAuth();

  // State to store table data for display
  const [tableData, setTableData] = useState({
    full_name: "",
    dob: "",
    test_type: "",
    primary_barcode: "",
    secondary_barcode: "",
    sample_collection_date: "",
    sample_collection_time: "",
    number_of_attempt: 0,
    store_location: "",
    rejection_reason: "",
    notes: "",
  });

  const [initialTableData, setInitialTableData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [rejectionModal, setRejectionModal] = useState(false);
  const [kitRejection, setKitRejection] = useState(false);
  const [appointmentRejection, setAppointmentRejection] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (rejectPath && !kitRejection && !appointmentRejection) {
      setShowRejectionModal(true);
    }
    if (auth) {
      auth.setTitle("APPOINTMENT CONFIRMATION");
    }
    fetchData();
  }, [
    id,
    auth?.title,
    location.state?.testkit,
    rejectionModal,
    fields,
    appointmentRejection,
    kitRejection,
  ]);

  useEffect(() => {
    if (resultPost) {
      setIsEditMode(false);
      fetchData();
      if (resultPost.reject_flag && kitRejection) {
        navigate(`/enter-barcode/${id}`);
      } else if (resultPost.reject_flag && appointmentRejection) {
        navigate(`/patient-record/`);
      }
    }
  }, [resultPost, appointmentRejection, fields]);

  // Populates table data from API response
  useEffect(() => {
    const fetchData = async () => {
      if (resultGet) {
        setBarcodeValue(resultGet?.primary_barcode);
        const blurredValues = {
          secondary_barcode: localStorage.getItem("secondary_barcode"),
          sample_collection_date: localStorage.getItem("sample_collection_date"),
          sample_collection_time: localStorage.getItem("sample_collection_time"),
          number_of_attempts: localStorage.getItem("number_of_attempt"),
        };

        const formattedDateTime = await TimeFormatter(
          resultGet.sample_collection_date,
          resultGet.sample_collection_time
        );

        const initialData = {
          full_name: resultGet.full_name,
          booking_id: localStorage.getItem('booking_id'),
          dob: resultGet.dob && format_dt_age(resultGet.dob),
          test_type: resultGet.test_type,
          primary_barcode: resultGet.primary_barcode,
          secondary_barcode:
            resultGet.secondary_barcode || blurredValues.secondary_barcode,
          sample_collection_date:
            resultGet.sample_collection_date || blurredValues.sample_collection_date,
          sample_collection_time:
            extractTime(formattedDateTime) || blurredValues.sample_collection_time,
          number_of_attempts:
            resultGet.number_of_attempts || blurredValues.number_of_attempts,
          store_location: resultGet.store_location,
          notes: resultGet.notes || blurredValues.notes,
        };
        setInitialTableData(initialData);
        setTableData(initialData);
      }
    };
    fetchData();
  }, [resultGet]);

  // Fetch appointment data from API
  const fetchData = async () => {
    const testkit = location.state?.testkit || id;
    const url = rejectPath
      ? `/api/phlebotomists-portal/kit-rejection/${testkit}/`
      : `/api/phlebotomists-portal/appointment-confirmation/${id}/`;

    callApi(null, url, "GET", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Handle back button click with unsaved changes check
  const handleBackClick = () => {
    navigate(`/enter-barcode/${id}`);
  };

  // Enable edit mode
  const handleEditClick = () => {
    setIsEditMode(true);
    setUnsavedChanges(true);
  };

  const handleKit = () => {
    setFields(testKitRejectionFields);
    setKitRejection(true); // Set Kit rejection state
    setShowRejectionModal(false); // Close the Rejection Modal
  };

  const handleAppointment = useCallback(() => {
    setFields(testApptRejectionFields);
    setAppointmentRejection(true);
    setShowRejectionModal(false);
  }, []);

  // Save changes and update the appointment
  const handleSaveClick = async () => {
    // Format the sample collection date and time
    const formattedDateTime = await TimeFormatter(
      tableData.sample_collection_date,
      tableData.sample_collection_time,
    );

    // Create the payload object with formatted datetime
    const payload = {
      ...tableData,
      sample_collection_time: formattedDateTime, // Correct property assignment
      notes: notes, // Properly include the notes field
      number_of_attempt: tableData.number_of_attempts, // Convert field name for backend
      primary_barcode: trimSpaces(tableData.primary_barcode)
    };

    // Call the API with the payload
    await callApi(
      payload,
      `/api/phlebotomists-portal/appointment-confirmation/${id}/`,
      "PUT",
      {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      },
    );
    setShowToaster(true);

    //Handle the API response
    if (resultPost) {
      const updatedData = resultPost;
      setNotes(updatedData.notes);
      setUnsavedChanges(false); // Reset unsaved changes flag on save
    }
  };

  // Cancel changes and revert to initial data
  const handleCancelClick = () => {
    if (unsavedChanges) {
      setShowConfirmationModal(true);
    } else {
      setIsEditMode(false);
      fetchData(); // Refresh data on cancel
    }
  };

  // Handle confirmation modal cancel action
  const handleCancelModalClick = () => {
    setShowConfirmationModal(false);
    // Reset to initial state and exit edit mode
    setTableData(initialTableData);
    setIsEditMode(false);
    setUnsavedChanges(false);
  };

  // Handle table cell edit
  const handleCellEdit = (fieldName, value) => {
    setTableData((prevData) => ({
      ...prevData,
      [fieldName]:
        fieldName === "number_of_attempts" ? parseInt(value, 10) : value,
    }));

    setUnsavedChanges(true);
  };

  // Handle notes field change
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
    setUnsavedChanges(true);
  };

  // Handle appointment rejection
  const handleRejection = async (formData) => {
    if (formData.number_of_attempt !== undefined) {
      formData.number_of_attempt = parseInt(formData.number_of_attempt, 10);
    }
    const formattedTime = new Date(formData.sample_collection_time);
    const confirmedData = {
      reject_flag: true,
      ...formData,
      ...tableData,
      sample_collection_time: formattedTime,
      testkit: location.state?.testkit,
      notes: formData.notes,
      bid: id,
      rejection_type: appointmentRejection ? "appointment" : "test_kit",
    };
    confirmedData &&
      callApi(confirmedData, putUrlReject, "PUT", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });
    setUnsavedChanges(false);
  };

  // Handles form submission for registration
  const handleRegister = async (formData) => {
    if (formData.number_of_attempt !== undefined) {
      formData.number_of_attempt = parseInt(formData.number_of_attempt, 10);
    }

    const confirmedData = {
      ...formData,
      ...tableData,
      notes,
      number_of_attempt: tableData.number_of_attempts,
    };

    await callApi(confirmedData, postUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
    setUnsavedChanges(false);
  };

  // Handle success message and exit edit mode
  useEffect(() => {
    if (message.postMessage === "Success: Your changes have been saved") {
      setSubmitSuccess(true);
      setTimeout(() => {
        setIsEditMode(false);
      });
    } else {
      toggleShowError();
    }
  }, [message]);

  useEffect(() => {
    if (resultPost?.[0]?.booking_reference_id) {
      auth?.showToast(
        "Confirm user details",
        "User details confirmed!",
        "../assets/mhc.png",
        <ArrowRightCircleFill />
      )
      const state = { secondary_barcode: secondary_barcode };
      navigate(`/confirmation-steps/${id}`, { state: { state } });
    }
  }, [resultPost])

  // Warn user of unsaved changes before unloading the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  // Confirm leaving without saving changes
  const handleConfirmLeave = () => {
    setShowConfirmationModal(false);
    // Reset the table data to initial values
    setTableData(initialTableData);
    setIsEditMode(false);
    setUnsavedChanges(false);
  };

  const handleSearchFormSubmit = (formData) => {
    setSubmittedFormData(formData); // Store the formData in state
    setModalShow(true); // Show the modal after form submission
  };

  /*kept for future reference*/
  // function convertToLocalTime(timeString) {
  //   // Extract hours, minutes, and seconds from the timeString
  //   const [hours, minutes, seconds] = timeString.split(":");

  //   // Create a Date object assuming the input time is in UTC
  //   const utcDate = new Date(
  //     Date.UTC(
  //       new Date().getUTCFullYear(),
  //       new Date().getUTCMonth(),
  //       new Date().getUTCDate(),
  //       parseInt(hours),
  //       parseInt(minutes),
  //       parseInt(seconds),
  //     ),
  //   );

  //   // Convert the UTC time to the local time string
  //   const localTimeString = utcDate.toLocaleTimeString();

  //   return localTimeString;
  // }

  const onToasterClose = () => {
    setShowToaster(false);
    setToasterMessage("");
  };

  // Automatically hides toaster after 3 seconds
  useEffect(() => {
    if (showToaster) {
      const timer = setTimeout(() => {
        setShowToaster(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToaster]);

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        {rejectPath ? (
          <div className="appointment-rejection">
            <h2 className="test-registration-description">
              Are you sure you want to {kitRejection ? 'reject' : 'cancel'} the following {kitRejection ? "test-kit" : "appointment"}?
            </h2>
            {isLoading && <p>Loading...</p>}
            {serverError && (
              <p className="server-error">Error: {serverError}</p>
            )}
            {Object.keys(tableData).length > 0 && (
              <>
                <SimpleTable
                  data={tableData}
                  editable={isEditMode}
                  onUpdate={handleCellEdit} // Pass update handler to SimpleTable
                  rejectPath={rejectPath}
                />
                <hr />
                {!isEditMode ? (
                  <SearchForm
                    data={notes}
                    fields={fields}
                    onBackClick={handleBackClick}
                    onEditClick={handleEditClick}
                    onSubmit={handleSearchFormSubmit}
                    validationSchema={rejectValidationSchema}
                  />
                ) : (
                  <Row>
                    <Col md={6}>
                      <FormInput
                        as="textarea"
                        className="notes-textarea"
                        label=""
                        name="notes"
                        onChange={handleNotesChange}
                        placeholder="Notes"
                        type="text"
                        value={notes ? notes?.notes : " "}
                      />
                    </Col>
                    <Col md={6}>
                      <div className="confirm-details-buttons">
                        <Button
                          label="Cancel"
                          onClick={handleCancelClick}
                          size="small"
                          variant="danger"
                        />
                        <Button
                          label="Save"
                          onClick={handleSaveClick}
                          size="small"
                          variant="secondary"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {modalShow && (
              <SuccessModal
                handleClose={""}
                label={"CONFIRM REJECTION"}
                message={
                  `Confirm that you are rejecting the ${kitRejection ? 'test-kit with barcode' : 'appointment with booking id'}: ` + (kitRejection ? barcodeValue : localStorage.getItem('booking_id'))
                }
                onClick={() => handleRejection(submittedFormData)} // Pass tableData to handleRejection
                onHide={() => setModalShow(false)}
                close={true}
                show={modalShow}
                variant={"danger"}
              />
            )}
            {showRejectionModal && (
              <Modal
                show={true}
                top
              >
                <Modal.Header>
                  <Modal.Title>{"Please select from these options for rejection:"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{"What do you want to reject?"}</Modal.Body>
                <Modal.Footer className="justify-center">
                  {
                    <>
                      <Button
                        label={"Kit"}
                        onClick={() => handleKit()}
                        size={"small"}
                        variant={"primary"}
                      />
                      <Button
                        label={"Appointment"}
                        onClick={() => handleAppointment()}
                        size={"small"}
                        variant={"danger"}
                      />
                    </>
                  }
                </Modal.Footer>
              </Modal>
            )}
          </div>
        ) : (
          <>
            <h2 className="test-registration-description">
              The following test will be registered
            </h2>
            {isLoading && (
              <div className="text-center">
                <Spinner />
              </div>
            )}
            {serverError && (
              <p className="server-error">Error: {serverError}</p>
            )}
            {Object.keys(tableData).length > 0 && (
              <>
                <SimpleTable
                  data={tableData}
                  editable={isEditMode}
                  onUpdate={handleCellEdit}
                />
                <hr />
                {!isEditMode ? (
                  <SearchForm
                    fields={testKitRegistrationFields}
                    onBackClick={handleBackClick}
                    onEditClick={handleEditClick}
                    onSubmit={handleRegister}
                    values={resultGet?.notes}
                  />
                ) : (
                  <Row>
                    <Col md={6}>
                      <FormInput
                        as="textarea"
                        className="notes-textarea"
                        label=""
                        name="notes"
                        onChange={handleNotesChange}
                        placeholder="Notes"
                        type="text"
                        value={resultGet?.notes}
                      />
                    </Col>
                    <Col md={6}>
                      <div className="confirm-details-buttons">
                        <Button
                          label="Cancel"
                          onClick={handleCancelClick}
                          size="small"
                          variant="danger"
                        />
                        <Button
                          label="Save"
                          onClick={handleSaveClick}
                          size="small"
                          variant="secondary"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {submitSuccess && (
              <SuccessModal
                handleClose={""}
                href={"/"}
                message={"Booking details updated successfully"}
                onHide={() => setSubmitSuccess(false)}
                show={submitSuccess}
                variant={"success"}
                close={false}
              />
            )}
            {showToaster && (
              <Toaster
                body={serverError ? serverError : "Details saved successfully"}
                header={serverError ? "Error" : "Success"}
                onClose={onToasterClose}
                position="top-end"
                show={showToaster}
              />
            )}
          </>
        )}
        <ConfirmationModal
          handleClose={() => setShowConfirmationModal(false)}
          handleConfirm={handleConfirmLeave}
          heading={"Warning -Unsaved changes-any modifications will be lost. "}
          labelOne={"OK"}
          labelTwo={"CANCEL"}
          message={
            "Please click OK to continue without saving or Cancel to return and save your changes."
          }
          onCancelClick={handleCancelModalClick}
          show={showConfirmationModal}
          variantOne={"secondary"}
          variantTwo={"danger"}
          close={false}
        />
        {tokenError && (
          <Toaster
            body={tokenError}
            header="Error"
            onClose={toggleShowError}
            position="top-end"
            show={tokenError}
          />
        )}
      </LoggedInLayout>
    </>
  );
}

export default AppointmentConfirmation;
