import React, { useEffect, useState } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { accountSchema } from "../../stories/Forms/validationSchema";
import {
  accountFields as initialAccountFields,
  links,
} from "../../stories/Forms/fields";
import Toaster from "../../stories/Toasts/Toaster";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { Button } from "../../stories/Buttons/Button";
import { Col, Row } from "react-bootstrap";
import { editUserUrl } from "../../utils/constants";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";

const Accounts = () => {
  const auth = useAuth();
  const [fields, setFields] = useState(initialAccountFields);
  const [isEdit, setIsEdit] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const {
    callApi,
    serverError,
    resultGet,
    resultPost,
    message,
    tokenError,
  } = useApis();

  // Determines if there are form errors
  const hasErrors = Object.keys(formErrors).length > 0;

  // Handles resetting fields to their initial values and reloading the page
  const handleBackClick = () => {
    window.location.reload();
    setIsEdit(false);
  };

  // Sets page title and fetches user data on component mount
  useEffect(() => {
    auth.setTitle("Accounts");
    callApi(null, editUserUrl, "GET", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  }, [auth.accessToken]);

  // Updates form fields with fetched user data
  useEffect(() => {
    if (resultGet) {
      const updatedFields = initialAccountFields.map((field) => {
        if (field.name && resultGet[field.name] !== undefined) {
          return {
            ...field,
            value: resultGet[field.name],
            readOnly: true,
            disabled: true,
          };
        }
        return field;
      });
      setFields(updatedFields);
      setIsEdit(true);
    }
  }, [resultGet]);

  // Manages toaster messages based on API call results
  useEffect(() => {
    if (resultPost) {
      setToasterMessage(message?.postMessage || "Operation successful");
      setShowToaster(true);
    } else if (serverError) {
      setToasterMessage(serverError);
      setShowToaster(true);
    }
  }, [resultPost, message, serverError]);

  // Automatically hides toaster after 3 seconds
  useEffect(() => {
    if (showToaster) {
      const timer = setTimeout(() => {
        setShowToaster(false);
        setToasterMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToaster]);

  // Prepares fields for editing, excluding certain fields
  const handleEdit = () => {
    const updatedFields = fields.map((field) => {
      if (field.name === "phlebotomist_org" || field.name === "email") {
        return {
          ...field,
          readOnly: true,
          disabled: true,
        };
      }
      return {
        ...field,
        readOnly: false,
        disabled: false,
      };
    });
    setFields(updatedFields);
    setIsEdit(false);
  };

  // Updates field values based on user input
  const handleFieldChange = (name, value) => {
    const updatedFields = fields.map((field) =>
      field.name === name ? { ...field, value } : field,
    );
    setFields(updatedFields);
  };

  // Submits updated user data
  const handleSubmit = async () => {
    const payload = fields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {});

    try {
      await callApi(payload, editUserUrl, "PUT", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });

      // Trigger the GET call to refresh the data
      callApi(null, editUserUrl, "GET", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });
      // Set fields to disabled after saving data
      const updatedFields = fields.map((field) => ({
        ...field,
        readOnly: true,
        disabled: true,
      }));

      setFields(updatedFields);
      setToasterMessage(
        message?.postMessage || "User details updated successfully.",
      );
      setShowToaster(true);
      setIsEdit(true);
    } catch (error) {
      setToasterMessage(
        error.message || "An error occurred while saving data.",
      );
      setShowToaster(true);
    }
  };

  // Closes the toaster and clears the message
  const onToasterClose = () => {
    setShowToaster(false);
    setToasterMessage("");
  };

  // Handles form errors state
  const handleErrorChange = (errors) => {
    setFormErrors(errors);
  };

  return (
    <>
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        <p className="form-title">
          To update your information, please edit the fields below
        </p>
        <Row>
          <Col md={8}>
            <DynamicForm
              askConfirmation={true}
              data={resultGet}
              fields={fields}
              onChange={handleFieldChange}
              onErrorChange={handleErrorChange}
              onSubmit={handleSubmit}
              serverError={serverError}
              validationSchema={accountSchema}
            />
          </Col>
        </Row>
        {(showToaster || tokenError) && (
          <Toaster
            body={tokenError ? tokenError : toasterMessage}
            header={tokenError ? "Error" : serverError ? "Error" : "Success"}
            onClose={onToasterClose}
            position="top-end"
            show={tokenError ? tokenError : showToaster}
          />
        )}
        {isEdit ? (
          <Button
            label={"Edit"}
            onClick={handleEdit}
            size={"small"}
            variant="primary"
          />
        ) : (
          <>
            <Button
              className={"m-2 ml-0"}
              disabled={hasErrors}
              label={"Save changes"}
              onClick={handleSubmit}
              size={"small"}
              type="submit"
              variant="primary"
            />
            <Button
              className={"m-2 ml-0"}
              label={"Cancel"}
              onClick={handleBackClick}
              size={"small"}
              type="submit"
              variant="danger"
            />
          </>
        )}
      </LoggedInLayout>
    </>
  );
};

export default Accounts;
