import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useAuth } from "./components/AuthContext/AuthContext";
import useApis from "./services/useAPI";
import { Button } from "./stories/Buttons/Button";
import ConfirmationModal from "./stories/Modals/ConfirmationModal";
import { logoutUrl } from "./utils/constants";

/**
 * Handles Logout funcionality.
 * Calls logout API and clears local storage
 * @returns
 */
function Logout() {
  const auth = useAuth();

  const [modalShow, setModalShow] = useState(false);

  // API hook initialisation
  const {
    callApi,
    serverError,
    message,
  } = useApis(logoutUrl, "POST");

  const handleClick = async () => {
    await callApi();
  };

  useEffect(() => {
    if (message) {
      message?.postMessage && auth.showToast("Success", "Logged out successfully!"
      )
      auth.logout();
    }

    if (serverError){
      auth?.showToast("Error", serverError)
    }

  }, [message]);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalConfirm = async () => {
    setModalShow(false);
    await handleClick();
  };

  return (
    <>
      <Container className="text-center">
        <Button
          className="logout-btn"
          label="Logout"
          onClick={() => {
            setModalShow(true);
          }}
          variant="outline"
        />
      </Container>
      <ConfirmationModal
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        heading="Confirm Logout"
        labelOne={"Cancel"}
        labelTwo={"Logout"}
        message="Are you sure you want to log out?"
        show={modalShow}
        vairantOne={"danger"}
        variantTwo={"primary"}
      />
    </>
  );
}

export default Logout;
