import React, { useEffect } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { loginSchema } from "../stories/Forms/validationSchema";
import { Container } from "react-bootstrap";
import { loginFields } from "../stories/Forms/fields";
import { useAuth } from "../components/AuthContext/AuthContext";
import useApis from "../services/useAPI";
import { useNavigate } from "react-router-dom";
import { loginUrl } from "../utils/constants";
import logo from "../assets/images/mhc.png";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";

function LoginForm() {
  const auth = useAuth();
  const navigate = useNavigate();

  // Custom hook for API calls and state management
  const {
    callApi,
    serverError,
    isLoading,
    resultPost,
  } = useApis(loginUrl, "POST");

  // Handle form submission
  const handleSubmit = async (data) => {
    const encodedPassword = btoa(data.password.trim());
    const payload = {
      email: data.email.toLowerCase(),
      password: encodedPassword,
    };
    await callApi(payload);
  };

  // Effect to handle login result and navigation
  useEffect(() => {
    auth.setTitle("Log in");

    if (resultPost) {
      {
        auth?.showToast(
          "Success",
          "Logged in successfully!",
        )
      }
      if (resultPost.mfa_enabled) {
        // Navigate to MFA setup if enabled
        navigate("/enable-webauthn");
        localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);
        localStorage.setItem("user_id", resultPost.id);
      } else {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("user_id", resultPost.id);
        resultPost && auth.login(resultPost);
        localStorage.setItem("access_token", resultPost.access_token);
        localStorage.setItem("refresh_token", resultPost.refresh_token);
        localStorage.setItem("email", resultPost.email);
        localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);
        setTimeout(() => {
          // Navigate to patient record after login
          navigate("/patient-record");
        }, 1000);
      }
    }
  }, [resultPost]);

  return (
    <>
      <Container className="">
        <LoggedOutLayout
          className={
            auth.title === "Access dashboard"
              ? "login-bg login-page-container "
              : ""
          }
          logoSrc={logo}
          title={auth.title}
        >
          <DynamicForm
            fields={loginFields}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            serverError={serverError}
            validationSchema={loginSchema}
          />
        </LoggedOutLayout>
      </Container>
    </>
  );
}

export default LoginForm;
