export const logoutConfirmation = "Are you sure, you want to log out?";

export const dirtyFromConfirmation =
  "Are you sure you want to leave without saving changes? Any unsaved modifications will be lost.";

export const sessionWarningMessage =
  "Your session is about to expire due to inactivity!";

export const logoutUrl = "/api/phlebotomists-portal/logout-user/";

export const changePasswordUrl = "/api/phlebotomists-portal/change-password/";

export const verifyOtpUrl =
  "/api/phlebotomists-portal/verify-otp-phlebotomist-test/";

export const enableMFAUrl =
  "/api/phlebotomists-portal/phlebotomist-enable-mfa/";

export const phlebotomistVerifyOtpUrl =
  "/api/phlebotomists-portal/phlebotomist-verify-otp/";

export const forgotPasswordUrl =
  "/api/phlebotomists-portal/phlebotmoist-forgot-password/";

export const loginUrl = "/api/phlebotomists-portal/login-user/";

export const resetPasswordUrl =
  "/api/phlebotomists-portal/phlebotomist-reset-password";

export const searchBookingsUrl = "/api/phlebotomists-portal/search-bookings/";

export const postUrlBarcode = "/api/phlebotomists-portal/validate-barcode/";

export const activateUserUrl = "/api/phlebotomists-portal/activate-user";

export const checkUserUrl =
  "/api/phlebotomists-portal/check-user/?activation_str=";

export const incompleteAppointmentUrl =
  "/api/phlebotomists-portal/incomplete-appointments/";

export const editUserUrl = "/api/phlebotomists-portal/edit-user/";

export const refreshTokenUrl = "/api/phlebotomists-portal/refresh-token/";

export const stepsOne = {
  Box1: `Ensure the tube is securely closed and gently mix by inverting the tube **5 times**`,
  Box2: `Ensure vessel has the correct label with the correct barcode label`,
  Box3: `Insert the sample into the specimen transport bag`,
  Box4: `Seal the specimen transport bag and place inside the outer box`,
  Box5: `Place the outer box of the collection into the return postal bag and seal it`,
  Box6: `Store the samples in a refrigerator at a temperature 2-8°C until is collected by the courier`,
};

export const stepsTwo = {
  Box1: `Ensure the tubes are securely closed and gently mix by inverting the **gold tube 5 times** and **lavender tube 8-10 times** in total`,
  Box2: `Ensure vessels have the correct labels with the correct barcode labels`,
  Box3: `Insert the samples into the specimen transport bag`,
  Box4: `Seal the specimen transport bag and place inside the outer box`,
  Box5: `Place the outer box of the collection into the return postal bag and seal it`,
  Box6: `Store the samples in a refrigerator at a temperature 2-8°C until is collected by the courier`,
};
