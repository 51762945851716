import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button } from "../Buttons/Button";
import useApis from "../../services/useAPI";
import imgActive from "../../assets/images/notifications-active.svg";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { incompleteAppointmentUrl } from "../../utils/constants";
import { useLocation } from "react-router-dom";

/**
 * Header component renders the header section of the application.
 * @param {Object} props - Props for the Header component.
 * @param {string} props.title - Title content for the header.
 * @param {string} props.img - Image source for the profile dropdown.
 * @returns {JSX.Element} - Rendered component.
 */

const Header = ({ title, img, isActivation, userInitials }) => {
  const auth = useAuth();
  const location = useLocation();
  /* Array of dropdown items */
  const [items, setItems] = useState([
    { label: "Manage account", href: "/account" },
    { label: "Change password", href: "/change-password" },
  ]);

  const [initials, setInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [notifications, setNotifications] = useState([]);
  const { callApi, resultGet } = useApis();

  /* Function to extract initials from username */
  const getInitials = (name) => {
    return userInitials === "undefined"
      ? "UN"
      : name.split(" ")[0]?.slice(0, 1).toUpperCase() +
          name.split(" ")[1]?.slice(0, 1).toUpperCase();
  };

  // Function to make string to sentence case
  const toSentenceCase = (str) => {
    if (str.length === 0) return "";
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  /* Function to store initials using getInitials method */
  useEffect(() => {
    if (userInitials) {
      const user = getInitials(userInitials);
      const fullName = toSentenceCase(userInitials.split("@")[0]);
      setInitials(user);
      setUserName(fullName);
    }
  }, [userInitials]);

  /* Function to fetch notifications */
  const fetchNotifications = async () => {
    callApi(null, incompleteAppointmentUrl, "GET", {
      Authorization: `Bearer ${auth?.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  useEffect(() => {
    // Check if the URL path matches `my-account/:id`
    if (!location.pathname.startsWith("/my-account/")) {
      setTimeout(() => {
        fetchNotifications();
      }, 2000);
    }
  }, [location.pathname]);

  // Effect to update notifications when resultGet changes
  useEffect(() => {
    if (resultGet) {
      if (
        resultGet.grouped_appointments &&
        resultGet.grouped_appointments.length > 0
      ) {
        setNotifications(
          resultGet.grouped_appointments.map((item) => ({
            label: `Pending ${item.patient_name} : ${item.count}`,
          })),
        );
      } else if (resultGet.processing_count !== undefined) {
        setNotifications([
          { label: `Pending appointments: ${resultGet.processing_count}` },
        ]);
      } else {
        setNotifications([]);
      }
    }
  }, [resultGet]);

  useEffect(() => {
    // Check localStorage for MFA status
    const mfaEnabled = JSON.parse(localStorage.getItem("mfa_enabled"));
    if (mfaEnabled === false) {
      setItems((prevItems) => {
        // Check if the item already exists to avoid duplicates
        const itemExists = prevItems.some(
          (item) => item.label === "Enable MFA",
        );
        if (!itemExists) {
          return [...prevItems, { label: "Enable MFA", href: "/enable-otp" }];
        }
        return prevItems;
      });
    }
  }, []);

  return (
    <>
      {/* Navbar component for the header */}
      <Navbar>
        <div className="page-header w-100">
          {/* Title content */}
          <h1>{title}</h1>
          {!isActivation && (
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="dropdown profile-dropdown-wrapper">
                {/* Dropdown button with icon */}
                <Button
                  className="btn btn-notification"
                  dropdown={true}
                  id="#"
                  items={
                    notifications.length > 0
                      ? notifications
                      : [{ label: "No new notifications" }]
                  }
                  svg={notifications.length > 0 ? imgActive : img}
                  type="dropdown"
                  variant="white"
                />
                {/* Profile button with initials */}
                <Button
                  className="btn btn-profile"
                  initials={initials}
                  items={items}
                  label={initials}
                  type="dropdown"
                  username={userName}
                  variant="gray"
                >
                  {" "}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Navbar>
    </>
  );
};

/* Define PropTypes for the Header component */
Header.propTypes = {
  /**
   * Title content for the header
   */
  title: PropTypes.string.isRequired,
  /**
   * Image source for the profile dropdown
   */
  img: PropTypes.string.isRequired,
  /**
   * Button label for the profile dropdown
   */
  initials: PropTypes.string,
};

export default Header;
