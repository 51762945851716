import { useEffect } from "react";
import { Button } from "../Buttons/Button";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Markdown } from "@storybook/blocks";

/**
 * SuccessModal component for displaying success messages.
 * @param {object} props - Component props.
 * @param {string} props.heading - Button contents.
 * @param {string} props.message - Optional click handler.
 * @param {boolean} props.show - Boolean to control modal visibility.
 * @param {Function} props.handleClose - Function to handle modal close event.
 */
function SuccessModal(props) {
  const { redirect } = props;
  const handleRedirect = () => {
    if (redirect) {
      redirect();
    }
  };

  useEffect(() => {
    if (!props.show) {
      handleRedirect();
    }
  }, [props.show, redirect]);


  const handleButtonClick = (event) => {
    // Prevent API call if the modal itself is clicked
    if (props.close) {
      event.stopPropagation();
    }
    props.onClick();
  };


  return (
    <>
      <Modal
        centered
        className="p-4 text-center success-modal"
        onHide={props.onHide}
        show={props.show}
      >
        <Modal.Header className=" border-0 align-self-center" closeButton={props.close} >
          <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Markdown>{props.message}</Markdown>
        </Modal.Body>
        <Button
          className={"mb-4 align-self-center"}
          href={props.href}
          label={props.label}
          onClick={handleButtonClick} // Ensure only this triggers the API call
          type="button"
          variant={props.variant}
        />
      </Modal>
    </>
  );
}

SuccessModal.propTypes = {
  /* Button contents */
  heading: PropTypes.string,
  /* Optional click handler */
  message: PropTypes.string.isRequired,
  /* Boolean to control modal visibility */
  show: PropTypes.bool,
  /* Function to handle modal close event */
  handleClose: PropTypes.func.isRequired,
};

SuccessModal.defaultProps = {
  /* Default heading */
  heading: "",
  message:
    "<strong>SUCCESS:</strong> Your account \n\n has been created" /* Default message */,
};

export default SuccessModal;
